<template>
    <v-container fluid no-gutters class="mx-0 px-0">
        <!-- <v-row v-if="parseInt(orientation) !== 90" align="center" justify=center>
            <v-col cols="12" align=center>
                Please tilt the phone on landscape mode
            </v-col>
            <v-col cols="12" align=center>
                <v-icon large>mdi-phone-rotate-landscape</v-icon>
            </v-col>
        </v-row> -->
        <v-row id="stats" justify="center" align="start" class="font-weight-bold mx-0 px-0">
            <v-col cols=1 align="center" class="mx-0 px-0 my-0">
                <v-row v-for="player in team1SelectedPlayers" :key="player.id" class="mx-0 px-0">
                    <v-col cols=12 class="mx-0 px-0">
                        <v-avatar color="grey">
                            <v-img v-if="player.image" :src="googleApiHost + '/' + player.image"/>
                            <v-icon v-else color="white"> mdi-account </v-icon>
                        </v-avatar>
                        <div>{{ `#${player.jersey}` }}</div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols=3 align="center" class="mx-0 px-0">
                <v-row class="mx-0 px-0">
                    <v-col cols=4 align="center">
                        <v-btn large fab outlined rounded :color="match.team1.color" @click="dialog=true; currentTeam = 1; pointsDialog = true; category = '2pts'">2pt</v-btn>
                    </v-col>
                    <v-col cols=4 align="center">
                        <v-btn large fab outlined rounded :color="match.team1.color" @click="dialog=true; currentTeam = 1; pointsDialog = true; category = '3pts'">3pt</v-btn>
                    </v-col>
                    <v-col cols=4 align="center">
                        <v-btn large fab outlined rounded :color="match.team1.color" @click="dialog=true; currentTeam = 1; pointsDialog = true; category = '1pt'">1pt</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0">
                    <v-col cols=6 align="end">
                        <v-btn large outlined :color="match.team1.color" @click="dialog=true; currentTeam = 1; category = 'dreb'">d. reb</v-btn>
                    </v-col>
                    <v-col cols=6 align="start">
                        <v-btn large outlined :color="match.team1.color" @click="dialog=true; currentTeam = 1; category = 'oreb'">o. reb</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0">
                    <v-col cols=6 align="end">
                        <v-btn large outlined :color="match.team1.color" @click="dialog=true; currentTeam = 1; category = 'to'">to</v-btn>
                    </v-col>
                    <v-col cols=6 align="start">
                        <v-btn large outlined :color="match.team1.color" @click="dialog=true; currentTeam = 1; category = 'stl'">stl</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0">
                    <v-col cols=6 align="end">
                        <v-btn large outlined :color="match.team1.color" @click="dialog=true; currentTeam = 1; category = 'asst'">asst</v-btn>
                    </v-col>
                    <v-col cols=6 align="start">
                        <v-btn large outlined :color="match.team1.color" @click="dialog=true; currentTeam = 1; category = 'blk'">blk</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0">
                    <v-col cols=6 align="end">
                        <v-btn large outlined :color="match.team1.color" @click="playersDialog=true; currentTeam = 1">sub</v-btn>
                    </v-col>
                    <v-col cols=6 align="start">
                        <v-btn large outlined :color="match.team1.color" @click="dialog=true; currentTeam = 1; foulsDialog = true; category = 'foul'">foul</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols=4 align="center" class="mx-0 px-0">
                <v-row class="font-weight-bold mx-0 px-0">
                    <v-col cols="12" class="mx-0 px-0">
                        {{ `${ match.cup ? 'Κύπελλο' : match.title + ', ' + (match.phase === 'regular_season' ? 'Regular Season' : (match.phase === 'placements' ? 'Προκριματικά' : 'Play Offs')) }` }}
                    </v-col>
                </v-row>
                <v-row class="my-0 font-weight-bold mx-0 px-0">
                    <v-col cols="12" class="mx-0 px-0">
                        {{ `Round ${match.round}, ${matchDate}` }}
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0" justify=center align="center">
                    <v-col cols="5" align="center" class="mx-0 px-0">
                        <v-img width="50px" height="auto" :src="googleApiHost + '/' + match.team1.logo"/>
                        <span class="font-weight-bold">{{ `${match.team1.name}` }}</span>
                    </v-col>
                    <v-col cols=1 align="center" class="mx-0 px-0">
                        vs
                    </v-col>
                    <v-col cols="5" align="center" class="mx-0 px-0">
                        <v-img width="50px" height="auto" :src="googleApiHost + '/' + match.team2.logo"/>
                        <span class="font-weight-bold">{{ `${match.team2.name}` }}</span>
                    </v-col>
                    <v-col cols="12" class="font-weight-bold my-0 py-0 mx-0 px-0">
                        <span class="mx-2" :style="`color: ${match.team1.color}`">{{ `${match.scoreTeam1}` }}</span>
                        <v-icon small>mdi-minus</v-icon>
                        <span class="mx-2" :style="`color: ${match.team2.color}`">{{ `${match.scoreTeam2}` }}</span>
                    </v-col>
                </v-row>
                <v-row align="center" class="mb-0 mt-5 mx-0 px-0">
                    <v-col cols="12" align="center" class="mx-0 px-0">
                        <v-select style="width:60px;" label="Quarter" :items="[1,2,3,4]" v-model="quarter"/>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center" class="mb-0 mx-0 px-0">
                    <v-col cols="2" align="end" class="mx-0 px-0 my-0">
                        <v-btn icon large color="black" @click="timerDialog = true">
                            <v-icon color="black">mdi-timer-edit-outline</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="6" class="match-timer py-0 mx-0 px-0 my-0" align="center">
                        <span>
                            <v-btn icon large dark color="black" @click="switchTimer()">
                                <v-icon large dark>{{paused ? 'mdi-pause' : 'mdi-play'}}</v-icon>
                            </v-btn>
                        </span>
                        <span style="font-size: 20px;">
                            {{ `${minutes}:${seconds}` }}
                        </span>
                    </v-col>
                    <v-dialog
                        v-model="timerDialog"
                        width="500"
                        hide-overlay
                        persistent
                        transition="dialog-bottom-transition"
                    >
                        <v-card class="px-5 py-5">
                            <v-row align=center justify=center>
                                <v-col cols=5>
                                    <v-text-field
                                        v-model="newMinutes"
                                        type="number"
                                        label="enter minutes"
                                    />
                                </v-col>
                                <v-col cols=2 align=center>
                                    :
                                </v-col>
                                <v-col cols=5>
                                    <v-text-field
                                        v-model="newSeconds"
                                        type="number"
                                        label="enter seconds"
                                    />
                                </v-col>
                            </v-row>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    text
                                    @click="timerDialog = false; changeTimer()"
                                >
                                    apply
                                </v-btn>
                                <v-btn
                                    color="error"
                                    text
                                    @click="timerDialog = false; newMinutes = null; newSeconds = null"
                                >
                                    close
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </v-col>
            <v-col cols=3 align="start" class="mx-0 px-0">
                <v-row class="mx-0 px-0" justify="start" align="start">
                    <v-col cols=4 align="center">
                        <v-btn large fab outlined rounded :color="match.team2.color" @click="dialog=true; currentTeam = 2; pointsDialog = true; category = '2pts'">2pt</v-btn>
                    </v-col>
                    <v-col cols=4 align="center">
                        <v-btn large fab outlined rounded :color="match.team2.color" @click="dialog=true; currentTeam = 2; pointsDialog = true; category = '3pts'">3pt</v-btn>
                    </v-col>
                    <v-col cols=4 align="center">
                        <v-btn large fab outlined rounded :color="match.team2.color" @click="dialog=true; currentTeam = 2; pointsDialog = true; category = '1pt'">1pt</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0">
                    <v-col cols=6 align="end">
                        <v-btn large outlined :color="match.team2.color" @click="dialog=true; currentTeam = 2; category = 'dreb'">d. reb</v-btn>
                    </v-col>
                    <v-col cols=6 align="start">
                        <v-btn large outlined :color="match.team2.color" @click="dialog=true; currentTeam = 2; category = 'oreb'">o. reb</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0">
                    <v-col cols=6 align="end">
                        <v-btn large outlined :color="match.team2.color" @click="dialog=true; currentTeam = 2; category = 'to'">to</v-btn>
                    </v-col>
                    <v-col cols=6 align="start">
                        <v-btn large outlined :color="match.team2.color" @click="dialog=true; currentTeam = 2; category = 'stl'">stl</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0">
                    <v-col cols=6 align="end">
                        <v-btn large outlined :color="match.team2.color" @click="dialog=true; currentTeam = 2; category = 'asst'">asst</v-btn>
                    </v-col>
                    <v-col cols=6 align="start">
                        <v-btn large outlined :color="match.team2.color" @click="dialog=true; currentTeam = 2; category = 'blk'">blk</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-0 px-0">
                    <v-col cols=6 align="end">
                        <v-btn large outlined :color="match.team2.color" @click="playersDialog=true; currentTeam = 2">sub</v-btn>
                    </v-col>
                    <v-col cols=6 align="start">
                        <v-btn large outlined :color="match.team2.color" @click="dialog=true; currentTeam = 2; foulsDialog = true; category = 'foul'">foul</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols=1 align="center" class="mx-0 px-0 my-0">
                <v-row v-for="player in team2SelectedPlayers" :key="player.id" class="mx-0 px-0">
                    <v-col cols=12 class="mx-0 px-0" align="center">
                        <v-avatar color="grey">
                            <v-img v-if="player.image" :src="googleApiHost + '/' + player.image"/>
                            <v-icon v-else color="white"> mdi-account </v-icon>
                        </v-avatar>
                        <div>{{ `#${player.jersey}` }}</div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="mx-10 px-10 my-0 py-0" align="center" justify="center">
            <v-col cols=7 align="center" class="mx-0 px-0">
                <span class="font-weight-bold"> Feed: </span>
                <v-card outlined class="py-2">
                    <v-virtual-scroll
                        :items="feed"
                        :item-height="50"
                        height="300"
                    >
                        <template v-slot:default="{ item }">
                            <v-list-item style="font-size: 16px;">
                                <span style="display: inline-flex;">{{ item.timestamp }}</span>
                                <v-avatar style="display: inline-flex;" class="mx-2">
                                    <v-img contain height="30px" :src="getTeamLogo(item)"/>
                                </v-avatar>
                                <span style="display: inline-flex;">{{ getLogText(item) }}</span>
                                <v-icon
                                    v-if="item.playerMatchStatus === 'stop'"
                                    icon
                                    color="red"
                                    class="mx-2"
                                >
                                    mdi-arrow-left
                                </v-icon>
                                <v-icon
                                    v-else-if="item.playerMatchStatus === 'begin'"
                                    icon
                                    color="green"
                                    class="mx-2"
                                >
                                    mdi-arrow-right
                                </v-icon>
                                <v-spacer/>
                                <v-list-item-action>
                                    <v-btn
                                        depressed
                                        icon
                                        small
                                        @click="deleteDialog = true; logId = item.logId"
                                    >
                                        <v-icon
                                            color="error"
                                        >
                                            mdi-trash-can
                                        </v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                    </v-virtual-scroll>
                    <v-dialog
                        v-model="deleteDialog"
                        width="500"
                        hide-overlay
                        persistent
                        transition="dialog-bottom-transition"
                    >
                        <v-card outlined class="py-5 px-5">
                            <v-row align=center justify=center>
                                <v-col cols=12 align=center>
                                    <span class="font-weight-bold"> Are you sure? </span>
                                </v-col>
                                <v-col cols=6 align=end>
                                    <v-btn outlined color="success" @click="deleteLog()">
                                        yes
                                    </v-btn>
                                </v-col>
                                <v-col cols=6 align=start>
                                    <v-btn outlined color="error" @click="deleteDialog = false">
                                        no
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-dialog>
                </v-card>
            </v-col>
            <v-col cols="12" align="center">
                <v-btn outlined large color="error" @click="endMatchDialog = true">
                    End Match
                </v-btn>
                <v-dialog
                    v-model="endMatchDialog"
                    width="500"
                    hide-overlay
                    persistent
                    transition="dialog-bottom-transition"
                >
                    <v-card outlined class="py-5 px-5">
                        <v-row align=center justify=center>
                            <v-col cols=12 align=center>
                                <span class="font-weight-bold"> Are you sure? </span>
                            </v-col>
                            <v-col cols=6 align=end>
                                <v-btn outlined color="success" @click="endMatch()">
                                    yes
                                </v-btn>
                            </v-col>
                            <v-col cols=6 align=start>
                                <v-btn outlined color="error" @click="endMatchDialog = false">
                                    no
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-dialog
            v-model="playersDialog"
            width="500"
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar absolute width="500">
                    <v-row align=center>
                        <v-col cols=8 class="text-h6">
                            {{ `Now playing: ${currentTeam == 1? team1SelectedPlayers.length : team2SelectedPlayers.length}` }}
                        </v-col>
                        <v-col cols=4 align=end>
                            <v-btn
                                icon
                                @click="playersDialog = false;"
                            >
                                <v-icon> mdi-close </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-toolbar>
                <v-row
                    align="center"
                    justify="center"
                    class="px-5 pb-5 pt-16"
                    style="cursor: pointer;"
                >
                    <v-col
                        cols=12
                        align="start"
                        v-for="player in currentTeam == 1? team1Players : team2Players"
                        :key="player.id"
                    >
                        <v-avatar left color="grey">
                            <v-img v-if="player.image" :src="googleApiHost + '/' + player.image"/>
                            <v-icon color="white" v-else large> mdi-account </v-icon>
                        </v-avatar>
                        {{ `#${player.jersey} ${player.surname}` }}
                        <v-btn
                            v-if="currentTeam == 1? team1SelectedPlayers.find(element => player.id === element.id) : team2SelectedPlayers.find(element => player.id === element.id)"
                            icon
                            color="red"
                            class="mx-2"
                            @click="removePlayer(player)"
                        >
                            <v-icon>
                                mdi-arrow-left
                            </v-icon>
                            out
                        </v-btn>
                        <v-btn
                            v-else
                            icon
                            color="green"
                            class="mx-2"
                            @click="addPlayer(player)"
                        >
                            in
                            <v-icon >
                                mdi-arrow-right
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        text
                        @click="playersDialog = false;"
                    >
                        close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog"
            width="500"
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar absolute width="500">
                    <v-row align=center>
                        <v-col cols=8 class="text-h6">
                            {{ category }}
                        </v-col>
                        <v-col cols=4 align=end>
                            <v-btn
                                icon
                                @click="dialog = false; choice = null; pointsDialog = false; foulsDialog = false; reboundDialog = false; category = null"
                            >
                                <v-icon> mdi-close </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-toolbar>
                <v-row v-if="reboundDialog" class="px-5 pb-5 pt-16" align=center justify=center>
                    <v-col cols="12" align=center>
                        <v-btn
                            color="black"
                            outlined
                            @click="category = 'oreb'; reboundDialog = false;"
                        >
                            Offensive Rebound
                        </v-btn>
                    </v-col>
                    <v-col cols="12" align=center>
                        <v-btn
                            color="black"
                            outlined
                            @click="category = 'dreb'; reboundDialog = false; currentTeam = currentTeam === 1 ? 2 : 1;"
                        >
                            Defensive Rebound
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-else-if="choice === null && pointsDialog" class="px-5 pb-5 pt-16" align=center justify=center>
                    <v-col cols="12" align=center>
                        <v-btn
                            color="black"
                            outlined
                            @click="choice = 'made'"
                        >
                            <v-icon color="success"> mdi-check </v-icon>
                            made
                        </v-btn>
                    </v-col>
                    <v-col cols="12" align=center>
                        <v-btn
                            color="black"
                            outlined
                            @click="choice = 'missed'"
                        >
                            <v-icon color="error"> mdi-close </v-icon>
                            missed
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-else-if="choice === null && foulsDialog" class="px-5 pb-5 pt-16" align=center justify=center>
                    <v-col cols="12" align=center>
                        <v-btn
                            color="warning"
                            outlined
                            @click="choice = 'personal'"
                        >
                            personal
                        </v-btn>
                    </v-col>
                    <v-col cols="12" align=center>
                        <v-btn
                            color="error"
                            outlined
                            @click="choice = 'technical'"
                        >
                            technical
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row
                    v-else
                    align="center"
                    justify="center"
                    class="px-5 pb-5 pt-16"
                    style="cursor: pointer;"
                >
                    <v-col
                        cols=12
                        align="start"
                        v-for="player in currentTeam == 1? team1SelectedPlayers : team2SelectedPlayers"
                        :key="player.id"
                        @click="setStat(player)"
                    >
                        <v-avatar color="grey" left>
                            <v-img v-if="player.image" :src="googleApiHost + '/' + player.image"/>
                            <v-icon color="white" v-else large> mdi-account </v-icon>
                        </v-avatar>
                        {{ `#${player.jersey} ${player.surname}` }}
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        text
                        @click="dialog = false; choice = null; pointsDialog = false; foulsDialog = false; reboundDialog = false; category = null"
                    >
                        close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import matchService from '@/services/match'
    import momentTimezone from 'moment-timezone'
    import teamService from '@/services/team'

    export default {
        data: () => {
            return {
                deleteDialog: false,
                endMatchDialog: false,
                reboundDialog: false,
                dialog: false,
                playersDialog: false,
                matchDate: null,
                match: null,
                googleApiHost: 'https://storage.googleapis.com',
                paused: false,
                team1Players: [],
                team1SelectedPlayers: [],
                team2Players: [],
                team2SelectedPlayers: [],
                currentTeam: null,
                team1: null,
                team2: null,
                pointsDialog: false,
                foulsDialog: false,
                choice: null,
                category: null,
                timer: null,
                newMinutes: null,
                newSeconds: null,
                timerDialog: false,
                timerInterval: null,
                feed: null,
                logId: null,
                quarter: 1
            }
        },
        watch: {
            match() {
                this.getFeed()
            },
            timer() {
                if (this.timer === 0 && this.quarter <= 3) {
                    this.quarter = this.quarter + 1
                }
            },
            quarter() {
                if (this.quarter <= 3) {
                    this.newMinutes = 10
                    this.newSeconds = 0
                    this.changeTimer()
                    this.switchTimer()
                }
            }
        },
        computed: {
            minutes() {
                let temp = Math.floor(this.timer / 60000)
                return (temp < 10 ? '0' : '') + temp
            },
            seconds() {
                let temp = Math.floor(((this.timer % 60000) / 1000))
                return (temp < 10 ? '0' : '') + temp
            },
            items() {
                const namesLength = this.names.length
                const surnamesLength = this.surnames.length
                const colorsLength = this.colors.length

                return Array.from({ length: 10000 }, (k, v) => {
                    const name = this.names[this.genRandomIndex(namesLength)]
                    const surname = this.surnames[this.genRandomIndex(surnamesLength)]

                    return {
                        color: this.colors[this.genRandomIndex(colorsLength)],
                        fullName: `${name} ${surname}`,
                        initials: `${name[0]} ${surname[0]}`
                    }
                })
            }
        },
        created() {
            this.timer = 600000
            this.getMatch().then(() => {
                this.getActivePlayers()
            })
        },
        methods: {
            endMatch() {
                let data = {
                    content: `${this.minutes === '10' ? (this.quarter - 1) * 10 : (10 - this.minutes) + (this.quarter - 1) * 10}:${(this.seconds === '00' ? '00' : (59 - this.seconds) < 10 ? '0' + (59 - this.seconds) : (59 - this.seconds))}`
                }
                matchService.endMatch(this.$route.params.matchId, data).then(resp => {
                    console.log(resp.data)
                    this.endMatchDialog = false
                    this.$router.go(-1)
                })
            },
            addPlayer(player) {
                if (this.currentTeam === 1 ? this.team1SelectedPlayers.length < 5 : this.team2SelectedPlayers.length < 5) {
                    this.currentTeam === 1 ? this.team1SelectedPlayers.push(player) : this.team2SelectedPlayers.push(player)
                    this.category = 'sub-in'
                    this.setStat(player)
                }
            },
            removePlayer(player) {
                this.currentTeam === 1 ? this.team1SelectedPlayers = this.team1SelectedPlayers.filter(element => player.id !== element.id) : this.team2SelectedPlayers = this.team2SelectedPlayers.filter(element => player.id !== element.id)
                this.category = 'sub-out'
                this.setStat(player)
            },
            getTeamLogo(log) {
                return this.googleApiHost + '/' + (this.match.team1.id === log.teamId ? this.match.team1.logo : this.match.team2.logo)
            },
            getMatch() {
                return matchService.getMatch(this.$route.params.matchId).then(resp => {
                    this.match = resp.data
                    if (this.match.matchDatetime === null) {
                        this.matchDate = null
                        this.matchTime = null
                    } else {
                        this.matchDate = momentTimezone(this.match.matchDatetime).tz('Europe/Athens').locale('el-GR').format('dddd D/M/y, HH:mm').toString()
                    }
                    this.team1 = resp.data.team1.id
                    this.team2 = resp.data.team2.id
                    this.fetchFilteredTeam1Players(resp.data.team1.id)
                    this.fetchFilteredTeam2Players(resp.data.team2.id)
                })
            },
            getActivePlayers() {
                this.team1SelectedPlayers = []
                this.team2SelectedPlayers = []
                matchService.getActivePlayers(this.$route.params.matchId).then(resp => {
                    resp.data.team1Players.forEach(element => {
                        this.team1SelectedPlayers.push(this.team1Players.filter(player => player.id === element.playerId)[0])
                    })
                    resp.data.team2Players.forEach(element => {
                        this.team2SelectedPlayers.push(this.team2Players.filter(player => player.id === element.playerId)[0])
                    })
                })
            },
            getLogText(log) {
                let temp = ''
                Object.keys(log).forEach(key => {
                    if (key === 'defensiveRebounds') {
                        temp += 'defensive rebound'
                    } else if (key === 'offensiveRebounds') {
                        temp += 'offensive rebound'
                    } else if (key === 'turnovers') {
                        temp += 'turnover'
                    } else if (key === 'turnovers') {
                        temp += 'turnover'
                    } else if (key === 'steals') {
                        temp += 'steal'
                    } else if (key === 'assists') {
                        temp += 'assist'
                    } else if (key === 'playerMatchStatus' && log[key] === 'begin') {
                        temp += 'subbed in'
                    } else if (key === 'playerMatchStatus' && log[key] === 'stop') {
                        temp += 'subbed out'
                    } else if (key === 'blockedShots') {
                        temp += 'block'
                    } else if (key === 'personalFouls') {
                        temp += 'personal foul'
                    } else if (key === 'technicalFouls') {
                        temp += 'technical foul'
                    } else if (key === 'freeThrowsMade' && log[key] === 1) {
                        temp += 'free throw made'
                    } else if (key === 'freeThrowAttempts' && log['freeThrowsMade'] === 0) {
                        temp += 'free throw missed'
                    } else if (key === 'twoPointsMade' && log[key] === 1) {
                        temp += '2pt made'
                    } else if (key === 'twoPointAttempts' && log['twoPointsMade'] === 0) {
                        temp += '2pt missed'
                    } else if (key === 'threePointsMade' && log[key] === 1) {
                        temp += '3pt made'
                    } else if (key === 'threePointAttempts' && log['threePointsMade'] === 0) {
                        temp += '3pt missed'
                    }
                })
                return `#${log.jersey} ${temp}`
            },
            getFeed() {
                matchService.getMatchLogs(this.$route.params.matchId).then((resp) => {
                    this.feed = resp.data.sort((a, b) => {
                        let timestampA = a.timestamp.split(':')
                        let timestampB = b.timestamp.split(':')
                        let msecA = parseInt(timestampA[0]) * 60000 + parseInt(timestampA[1]) * 1000
                        let msecB = parseInt(timestampB[0]) * 60000 + parseInt(timestampB[1]) * 1000
                        if (msecA < msecB) {
                            return 1
                        }
                        if (msecA > msecB) {
                            return -1
                        }
                        return 0
                    })
                })
            },
            deleteLog() {
                let log = this.feed.filter((element) => this.logId === element.logId)[0]
                matchService.deleteMatchLog(this.match.id, this.logId).then((resp) => {
                    this.deleteDialog = false
                    this.feed = resp.data
                    if (log.playerMatchStatus === 'stop' || log.playerMatchStatus === 'begin') {
                        this.getActivePlayers()
                    }
                    this.getMatch()
                })
            },
            setStat(player) {
                let data = {
                    teamId: this.currentTeam === 1 ? this.team1 : this.team2,
                    playerId: player.id,
                    jersey: player.jersey,
                    timestamp: `${this.minutes === '10' ? (this.quarter - 1) * 10 : (10 - this.minutes) + (this.quarter - 1) * 10}:${(this.seconds === '00' ? '00' : (59 - this.seconds) < 10 ? '0' + (59 - this.seconds) : (59 - this.seconds))}`
                }
                switch (this.category) {
                case '1pt':
                    if (this.choice === 'made') {
                        data.freeThrowsMade = 1
                        data.freeThrowAttempts = 1
                    } else {
                        data.freeThrowAttempts = 1
                    }
                    break
                case '2pts':
                    if (this.choice === 'made') {
                        data.twoPointsMade = 1
                        data.twoPointAttempts = 1
                    } else {
                        data.twoPointAttempts = 1
                    }
                    break
                case '3pts':
                    if (this.choice === 'made') {
                        data.threePointsMade = 1
                        data.threePointAttempts = 1
                    } else {
                        data.threePointAttempts = 1
                    }
                    break
                case 'dreb':
                    data.defensiveRebounds = 1
                    break
                case 'oreb':
                    data.offensiveRebounds = 1
                    break
                case 'to':
                    data.turnovers = 1
                    break
                case 'stl':
                    data.steals = 1
                    break
                case 'asst':
                    data.assists = 1
                    break
                case 'blk':
                    data.blockedShots = 1
                    break
                case 'foul':
                    if (this.choice === 'personal') {
                        data.personalFouls = 1
                    } else {
                        data.technicalFouls = 1
                    }
                    break
                case 'sub-in':
                    data.playerMatchStatus = 'begin'
                    break
                case 'sub-out':
                    data.playerMatchStatus = 'stop'
                    break
                default:
                    break
                }
                matchService.importMatchLog(this.match.id, data).then((resp) => {
                    if ((this.category === '2pts' || this.category === '3pts') && this.choice === 'made') {
                        this.category = 'asst'
                        this.choice = null
                        this.pointsDialog = false
                        this.foulsDialog = false
                        this.reboundDialog = false
                    } else if ((this.category === '2pts' || this.category === '3pts') && this.choice === 'missed') {
                        this.reboundDialog = true
                        this.choice = null
                        this.pointsDialog = false
                        this.foulsDialog = false
                    } else if (this.category === 'stl') {
                        this.category = 'to'
                        this.currentTeam = this.currentTeam === 1 ? 2 : 1
                        this.choice = null
                        this.pointsDialog = false
                        this.foulsDialog = false
                        this.reboundDialog = false
                    } else {
                        this.dialog = false
                        this.pointsDialog = false
                        this.foulsDialog = false
                        this.category = null
                        this.choice = null
                        this.reboundDialog = false
                    }
                    this.match = resp.data
                })
            },
            fetchFilteredTeam1Players(id) {
                this.loading = true
                teamService.getTeam(id).then(resp => {
                    this.team1Players = resp.data.players.filter(element => element.jersey !== null).sort((a, b) => {
                        if (a.jersey < b.jersey) {
                            return -1
                        }
                        if (a.jersey > b.jersey) {
                            return 1
                        }
                        return 0
                    })
                    this.loading = false
                })
            },
            fetchFilteredTeam2Players(id) {
                this.loading = true
                teamService.getTeam(id).then(resp => {
                    this.team2Players = resp.data.players.filter(element => element.jersey !== null).sort((a, b) => {
                        if (a.jersey < b.jersey) {
                            return -1
                        }
                        if (a.jersey > b.jersey) {
                            return 1
                        }
                        return 0
                    })
                    this.loading = false
                })
            },
            getPlayerText(item) {
                return `#${item.jersey} ${item.name} ${item.surname}`
            },
            // openFullscreen() {
            //     var elem = document.getElementById('stats')
            //     console.log(elem)
            //     if (elem.requestFullscreen) {
            //         elem.requestFullscreen()
            //     } else if (elem.webkitRequestFullscreen) { /* Safari */
            //         elem.webkitRequestFullscreen()
            //     } else if (elem.msRequestFullscreen) { /* IE11 */
            //         elem.msRequestFullscreen()
            //     }
            // },
            switchTimer() {
                this.paused = !this.paused
                if (!this.paused) {
                    clearInterval(this.timerInterval)
                } else if (this.timer > 0) {
                    this.timerInterval = setInterval(() => {
                        this.timer = this.timer - 1000
                    }, 1000)
                }
            },
            changeTimer() {
                this.timer = parseInt(this.newMinutes) * 60000 + parseInt(this.newSeconds) * 1000
            }
        }
    }
</script>

<style scoped>
    .match-timer{
        font-weight: 700;
        border: 2px solid black;
    }

    .match-timer-danger{
        border-color: red !important;
        color: red !important;
    }
</style>
